import apiClient from "./apiClient";
import {config} from "../config";
import {useQuery} from "@tanstack/react-query";
import {useAppContext} from "../libs/appContextLib";


export const useFetchAccountSearch = ({debouncedInputValue}) => {
    const {currUser, dataStatus} = useAppContext();
    return useQuery({
        queryKey: [`${currUser.customerId}_accounts`, debouncedInputValue],
        queryFn: async () => {
            const response = await apiClient.get(
                `${config.REACT_APP_BACKEND_HOST}/accounts-list`,
                {
                    params: {
                        'search_string': debouncedInputValue || ''
                    }
                }
            )
            // console.log('API Response:', response.data);
            return response.data;
        },
        enabled: !!currUser?.customerId,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 60 * 36,
        cacheTime: 1000 * 60 * 60 * 36,
        retry: false
    })
};
